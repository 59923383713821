import {LoadThreadsAndInfo} from "../user/ai/chat/LoadThreadsAndInfo";

export interface Model {
    id: string
    name: string
    modelType: ModelType
    modelKey: ModelKey
    prompt: string
    imageUrl: string
    isDraft: boolean
    usages: number
    callCost: number
    description: string
    totalCostInUsd: number
    usedTokens: number
    assemblyAiCostInUsd?: number
    pollyCostInUsd?: number
}

export interface ModelRequest {
    requestType: "CREATE" | "EDIT"
    id?: string
    name: string
    modelType: ModelType
    modelKey: ModelKey
    prompt: string
    imageUrl: string
    isDraft: boolean
    callCost: number
    description: string
}

export const modelTypes = {
    "chat": "Chat",
    "speaking": "Konwersacje",
}

type ModelType = "chat" | "speaking"

export const modelOptions = {
    "anthropic.claude-v2:1": "Claude 2.1",
    "anthropic.claude-instant-v1": "Claude Instant",
    "amazon.titan-text-express-v1": "Titan express",
    "gpt-4o-mini-2024-07-18": "GPT 4o mini",
    "gpt-4o-2024-11-20": "GPT 4o",
    "vision-gpt": "Vision GPT",
};

type ModelKey =
    "anthropic.claude-v2:1"
    | "anthropic.claude-instant-v1"
    | "gpt-4o-mini-2024-07-18"
    | "gpt-4o-2024-11-20"
    | "amazon.titan-text-express-v1"
    | "vision-gpt";

export function getModelById(models: Model[], id: string): Model | null {
    return models.find(model => model.id === id) ?? null;
}

export function getSelectedChatModelOrDefault(loadedThreadsAndInfo: LoadThreadsAndInfo) {
    return (loadedThreadsAndInfo.selectedChatModelId ?
        (getModelById(loadedThreadsAndInfo.models, loadedThreadsAndInfo.selectedChatModelId)
            ? getModelById(loadedThreadsAndInfo.models, loadedThreadsAndInfo.selectedChatModelId)!!
            : loadedThreadsAndInfo.models[0])
        : loadedThreadsAndInfo.models[0]);
}

export function getSelectedSpeakingModelOrDefault(loadedThreadsAndInfo: LoadThreadsAndInfo) {
    return (loadedThreadsAndInfo.speakingSettings && loadedThreadsAndInfo.speakingSettings.modelId ?
        (getModelById(loadedThreadsAndInfo.models, loadedThreadsAndInfo.speakingSettings.modelId)
            ? getModelById(loadedThreadsAndInfo.models, loadedThreadsAndInfo.speakingSettings.modelId)!!
            : loadedThreadsAndInfo.models[0])
        : loadedThreadsAndInfo.models[0]);
}