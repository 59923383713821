import {Trace, Traces} from "../../../model/user/general/Trace";
import usePagination from "../../../hooks/usePagination";
import {TraceService} from "../../../service/TraceService";
import PaginationLoadMore from "../../ui/pagination/PaginationLoadMore";
import React from "react";

interface AdminTracesContentProps {
    traces: Traces
}

export default function AdminTracesContent(props: AdminTracesContentProps) {
    const {traces} = props

    const {
        items: tracesItems,
        setItems,
        loadMore,
        nextPageKey,
        setNextPageKey,
        sentinelRef,
        isAutoLoadEnabled
    } = usePagination<Trace, any>(TraceService.getTraces, traces.items, traces.nextPageKey, true, traces.items[0]?.timestamp.split("T")[0] ?? new Date().toISOString().split("T")[0]);
    return <>
        <div style={{overflowX: "auto"}} className='text-center'>
            <table className="table bg-light-dark text-white px-1 text-center mx-auto fs-11"
                   style={{boxSizing: "unset", overflowX: "visible"}}>
                <thead>
                <tr>
                    <th scope="col">ID sesji</th>
                    <th scope="col">Data</th>
                    <th scope="col">Typ</th>
                    <th scope="col">Ścieżka</th>
                    <th scope="col">Parametry</th>
                    <th scope="col">Dodatkowe informacje</th>
                    <th scope="col">UserID</th>
                    <th scope="col">IP</th>
                </tr>
                </thead>
                <tbody>
                {
                    tracesItems.map((trace: Trace) => (
                        <tr key={trace.sessionId + trace.timestamp} className='g-4'>
                            <th scope="row">{trace.sessionId?.substring(0, 8)}</th>
                            <td>{trace.timestamp}</td>
                            <td>{trace.traceType}</td>
                            <td>{trace.pathname}</td>
                            <td style={{
                                maxWidth: '250px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>{trace.searchParams}</td>
                            <td style={{
                                maxWidth: '300px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                // whiteSpace: 'nowrap'
                            }}>{trace.additionalInfo}</td>
                            <td>{trace.userId?.substring(0, 8)}</td>
                            <td>{trace.ip}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
        <PaginationLoadMore isAutoLoadEnabled={isAutoLoadEnabled} nextPageKey={nextPageKey} loadMore={loadMore}
                            sentinelRef={sentinelRef}/>
    </>
}