import React from "react";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import Heading from "../../common/header/Heading";
import CalendlyInlineLazy from "../../user/calendly/CalendlyInlineLazy";
import {BubbleNotificationWithPlaceholders} from "../../ui/motion/BubbleNotificationWithPlaceholders";

export default function ExtraordinarySupportStudent() {
    return <>
        <Heading customStyles='mb-0 text-center mw-md-2xl mx-auto'
                 customTitleStyles='font-heading mb-4 fs-5 text-white'
                 title='Jak zostać NAJLEPSZYM uczniem?'
                 customSubtitleStyles='fs-10 fw-medium text-secondary-light mw-md-xl mx-auto'
                 subtitle='Bez siedzenia godzinami w książkach, bez żadnych korepetycji, bez stresu i bez wychodzenia z domu.'
                 badgeText='BEZPŁATNA KONSULTACJA'
        />
        <div className='mw-md-md mx-auto'>
            <p className='fw-medium text-success fs-11 text-center'>Po wybraniu daty zobaczysz więcej
                informacji na temat naszej potencjalnej współpracy.</p>
        </div>
        <div className='mw-md-5xl mx-auto'>
            <div className='text-center'>
                <CalendlyInlineLazy
                    onEventTypeViewed={() => {
                        TraceService.addTrace(TraceType.CalendlyEventTypeViewed)
                    }}
                    onDateAndTimeSelected={() => {
                        TraceService.addTrace(TraceType.CalendlyDateAndTimeSelected)
                    }}
                    calendlyUrl='https://calendly.com/produkacja/indywidualny-plan-dzialania?hide_gdpr_banner=1'/>
            </div>
            <BubbleNotificationWithPlaceholders
                message={`{name} umówił/a się {time} minut temu`}
                delay={5000}
                duration={5000}
            />
        </div>
    </>
}