import Heading from "../../common/header/Heading";
import VideoWithPlayButton from "../../ui/video/VideoWithPlayButton";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import {MW_FULL_FUNNEL_EXPERIMENT_2} from "../../../util/ExperimentUtil";
import React from "react";
import {useSearchParams} from "react-router-dom";
import {BubbleNotificationWithPlaceholders} from "../../ui/motion/BubbleNotificationWithPlaceholders";
import CalendlyInlineLazy from "../../user/calendly/CalendlyInlineLazy";
import {DiagnosticService} from "../../../service/DiagnosticService";


export default function FunnelMWParentVSL() {
    const [searchParams] = useSearchParams();

    const variation = searchParams.get('variation');
    const source = searchParams.get('source');

    return <>
        <Heading customStyles='mb-0 text-center mw-md-3xl mx-auto'
                 customTitleStyles='font-heading mb-4 fs-5 text-white'
                 title='Jak pomóc swojemu dziecku w zostaniu NAJLEPSZYM uczniem?'
                 badgeText='BEZPŁATNE SZKOLENIE'
        />
        <div className='mw-md-5xl mx-auto'>
            <div className='mw-md-3xl mx-auto mb-8 position-relative'>
                <VideoWithPlayButton vimeoVideoId='1034881080' id='vsl-ked-matura-parent'
                                     trackStatistics={true}
                                     onPlay={() => {
                                         TraceService.addTraceWithExperiment(TraceType.PlayVideo, MW_FULL_FUNNEL_EXPERIMENT_2, variation, source)
                                         TraceService.addTrace(TraceType.PlayVideo, `vsl-mw`)
                                     }}/>
            </div>
            <div className='text-center'>
                <h2
                    className='font-heading mb-4 fs-6 text-white mt-16'
                    style={{letterSpacing: '-1px'}}
                >
                    Umów się na bezpłatną konsultację
                </h2>
                <CalendlyInlineLazy
                    onEventTypeViewed={() => {
                        TraceService.addTraceWithExperiment(TraceType.CalendlyEventTypeViewed, MW_FULL_FUNNEL_EXPERIMENT_2, variation, source)
                    }}
                    onDateAndTimeSelected={() => {
                        TraceService.addTraceWithExperiment(TraceType.CalendlyDateAndTimeSelected, MW_FULL_FUNNEL_EXPERIMENT_2, variation, source)
                    }}
                    onEventScheduled={() => {
                        TraceService.addTraceWithExperiment(TraceType.CalendlyEventScheduled, MW_FULL_FUNNEL_EXPERIMENT_2, variation, source)
                        try {
                            // @ts-ignore
                            gtag('event', 'conversion', {'send_to': 'AW-16562613681/2oEFCOCbnOAZELHb1dk9'});
                        } catch (error) {
                            DiagnosticService.addDiagnostic({
                                error: error,
                                fullEvent: {},
                                functionName: "FunnelMWParentVSL",
                                additionalInfo: "There was an error sending conversions to google."

                            })
                        }
                    }}
                    calendlyUrl='https://calendly.com/produkacja/indywidualny-plan-dzialania-dla-twojego-dziecka?hide_gdpr_banner=1'/>
            </div>
            <BubbleNotificationWithPlaceholders
                message={`{name} umówił/a się {time} minut temu`}
                delay={5000}      // Show after 3 or 60 seconds
                duration={5000}   // Hide after 5 seconds
            />
        </div>
    </>
}